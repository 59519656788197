import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export default class TextReveal extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      line: [],
      lineParent: [],
      topText: {},
      bottomText: {}
    };
  }

  mount() {
    let that = this;
    console.log('hello');

    let splitTextBackground = new SplitText(this.ref.bottomText, {type: "chars, words"});
    // let chars = splitText.chars; //an array of all the divs that wrap each character


    let splitText = new SplitText(this.ref.topText, {type: "chars, words"});
    let chars = splitText.chars; //an array of all the divs that wrap each character

    gsap.fromTo(chars, {
      opacity: 0
    }, {
      opacity:1,
      duration:0.01,
      stagger: 0.05,
      scrollTrigger: {
        trigger: this.element,
        start: "top bottom-=100",
        end: "center center+=50",
        scrub: true,
        markers: false,
        ease: 'power4.inOut',

      }
    })

  }


}